
import Header from '../components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles/App.scss';
import HeaderMobile from '../components/HeaderMobile';
import { Col, Row, Image, Button } from 'react-bootstrap';
import HomeBannerDesktop from './../media/dashboard-2.jpg';

function AdvantagePortal() {

  const navigate = () => {
    window.open("https://demo.nscopelabs.com", "_blank")
  }

  return (
    <>
      <div className='gradient-bg' style={{ marginBottom: '80px' }}>
        <Header />
        <div className='main_content'>
          {/* navbar */}
          <HeaderMobile />
          {/* navbar ends */}

          {/* landing banner */}
          <div className='banner_wrapper advantage_portal_banner_wrapper'>
            <Row>
              <Col className="banner_hero" style={{ textAlign: 'center' }} >
                <h1 style={{ marginBottom: '5px' }}>The Power of PCI Compliance Through NScope</h1>
                <p style={{ margin: '50px 20%' }}>NScope Advantage solves the annual challenge of PCI scoping and maintaining an asset inventory.  Take a look and see how PCI compliance is simplified.</p>

                <Button onClick={navigate} className="banner_btns round_btn cyan_btn"><span>Advantage Portal - Click Here</span></Button>
                <p style={{ marginBottom: 0 }}><small>Login with nscopedemo/Demouser123!</small></p>
              </Col>
            </Row>
          </div>
          {/* landing banner ends */}
        </div>

        {/* Features box starts */}
        <div className='features_box_wrapper' style={{ bottom: '-50px' }}>
          <Image alt="main banner" style={{ boxShadow: "0 8px 6px -6px black", width: '100%', maxWidth: "950px" }} src={HomeBannerDesktop} />
        </div>
        {/* Features box starts */}
      </div >
      {/* gradient ends */}

      {/* case studies ribbon */}
      <div>
        <h2 style={{ textAlign: 'center', margin: '10px 0', fontWeight: 'bold' }}>Dive Deeper Into NScope</h2>
        <br />
      </div>
      <div className='advantages_box_wrapper' style={{ position: 'relative', bottom: 0 }}>
        <div className="box gradient-bg m-0">
          <p className="title">On-Prem &amp; Cloud</p>
          <p className="sub_title">In-scope PCI relevant assets along with business partner services are categorized and assessed.</p>
        </div>
        <div className="box gradient-bg m-0">
          <p className="title">Assessor Level Analysis</p>
          <p className="sub_title">Determining the applicable requirements for CAT 1 or CAT 2 components that impact your Cardholder Data Environment.</p>
        </div>
        <div className="box gradient-bg m-0">
          <p className="title">Compliance Workflows</p>
          <p className="sub_title">Collecting appropriate evidence to submit as artifacts to satisfy PCI requirements.</p>
        </div>
      </div>
      <br />
      {/* case studies ribbon */}
    </>
  );
}

export default AdvantagePortal;
