import { useState } from 'react';
import { Image } from 'react-bootstrap';

import AWS_Logo from './../media/aws-logo.png';
import Azure_Logo from './../media/azure-logo.png';
import Google_Cloud_Logo from './../media/google-cloud-logo.png';
import Qualys_Logo from './../media/qualys-logo.svg';
import Snowflake_Logo from './../media/snowflake-logo.svg';
import DemoModal from './DemoModal';


const CaseStudiesRibbon = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = (e) => {
        e.preventDefault()
        handleShow();
    }

    return <>
        <DemoModal handleClose={handleClose} show={show} />
        <div style={{ width: '100%', }}>
            <div className='case_studies_ribbon'>
                <Image alt="aws" src={Google_Cloud_Logo} />
                <Image alt="aws" src={Azure_Logo} />
                <Image alt="aws" src={AWS_Logo} />
                <Image alt="aws" src={Snowflake_Logo} />
                <Image alt="aws" src={Qualys_Logo} />
            </div>
            <div>
                <p className='read_case_studies'><a href="/home" onClick={handleClick} >Request a Demo &nbsp; &gt;</a></p>
            </div>
        </div>
    </>
}

export default CaseStudiesRibbon;