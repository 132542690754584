
import Header from '../components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles/App.scss';
import HeaderMobile from '../components/HeaderMobile';
import { Col, Row, Image } from 'react-bootstrap';
import Logo from './../media/svg/nScope_logo_light.png';
import CaseStudiesRibbon from '../components/CaseStudiesRibbon';

function CompanyInfo() {
  return (
    <>
      <div className='gradient-bg' style={{ marginBottom: '20px' }}>
        <Header />
        <div className='main_content'>
          {/* navbar */}
          <HeaderMobile />
          {/* navbar ends */}

          {/* landing banner */}
          <div className='banner_wrapper' style={{ marginBottom: '25px' }}>
            <Row>
              <Col className="banner_hero" style={{ textAlign: 'center' }} >
                {/* <h1 style={{ marginBottom: '5px' }}>The Power of PCI Compliance Through NScope</h1> */}
                <Image width="60%" src={Logo} alt="logo" />
                <div className='company_info_wrapper'>
                  <p style={{ margin: '50px 0' }}>Our company was founded with the sole purpose to support organizations that utilize credit cards to process transactions.  We are based out of Apopka, Florida USA with clients globally.
                  </p>
                  <p>
                    The NScope Advantage Portal was designed by industry professionals that have maintained compliance for PCI Level 1 & 2 Merchants during the last 10 years and counting.  Our staff have supported many industries including Consumer Goods (Kimberly-Clark), Entertainment (Disney), Financial (Wells Fargo), Gaming (EA Sports), and Transportation (JetBlue Airways).
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          {/* landing banner ends */}
        </div>

      </div >
      {/* gradient ends */}

      {/* case studies ribbon */}
      <div>
        <h2 style={{ textAlign: 'center', margin: '30px 0' }}>NScope Industry Integration</h2>
      </div>
      <CaseStudiesRibbon />
      {/* case studies ribbon */}
    </>
  );
}

export default CompanyInfo;
