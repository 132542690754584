
import Header from '../components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles/App.scss';
import HeaderMobile from '../components/HeaderMobile';
import { Col, Row } from 'react-bootstrap';
// import HomeBannerDesktop from './../media/home_banner_desktop.png';

function PCIServices() {
  return (
    <>
      <div className='gradient-bg' style={{ marginBottom: 0 }}>
        <Header />
        <div className='main_content'>
          {/* navbar */}
          <HeaderMobile />
          {/* navbar ends */}

          {/* landing banner */}
          <div className='banner_wrapper' style={{ marginBottom: '25px', paddingTop: '40px' }}>
            <Row>
              <Col className="banner_hero"  >
                <h1 style={{ marginBottom: '5px', textAlign: 'center' }}>PCI Services</h1>
                <p style={{ margin: '50px 10%' }}>PCI DSS v4.0 GAP Analysis – identifying the difference between current state of a cardholder data environment and the desired/required future state.</p>
                <p style={{ margin: '50px 10%' }}>Control Analysis / Requirement Satisfaction – planning in advance to be PCI compliant by understanding the mandatory minimum expectations. .</p>
                <p style={{ margin: '50px 10%' }}>SAQ Prep® – Navigating through Self Assessment Questionnaire completion process successfully with the necessary oversight to report organizational compliance with requirements.</p>
              </Col>
            </Row>
          </div>
          {/* landing banner ends */}
        </div>
      </div >
      {/* gradient ends */}
    </>
  );
}

export default PCIServices;
