import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Image } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import NscopeLogo from './../media/svg/nScope_logo_light.png';
import DemoModal from './DemoModal';

const Header = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        let scrollpos = window.scrollY;
        window.addEventListener('scroll', function () {
            scrollpos = window.scrollY;
            if (scrollpos > 10) {
                console.log('scrrooll add classs')
                setScroll(true)
            }
            else {
                console.log('scrrooll remove classs')
                setScroll(false)
            }
        });
    }, [])

    return <>
        <DemoModal handleClose={handleClose} show={show} handleShow={handleClose} />
        <Row className={`navbar ${scroll ? 'add-bg-color' : ''}`} style={{ margin: '0' }}  >
            <Col lg={{ order: 'first', span: 2 }}><Link to="/"><Image width="100%" alt="banner-logo" src={NscopeLogo} /></Link></Col>
            <Col lg={{ span: 7 }} className="navbar_links">
                <div style={{ display: 'flex', gap: '30px' }}>
                    <div><Link to="/advantage-portal">Advantage Portal</Link></div>
                    {/* <a href="https://nscope.acbss.com">Advantage Portal</a> */}
                    <div><Link to="/pci-services">PCI Services</Link></div>
                    <div><Link to="/education-training">Education &amp; Training</Link></div>
                    <div><Link to="/company-info">Company Info</Link></div>
                </div>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} lg={{ order: 'last', span: 3 }}>
                <BsSearch color="white" fontSize={17} /> &nbsp;
                <Button onClick={handleShow} className="ml-15 round_btn cyan_btn"><span>Request a Demo</span></Button>
            </Col>
        </Row>
    </>
};

export default Header;