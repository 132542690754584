import { useState } from 'react';
import { Button, Col, Row, Image } from 'react-bootstrap';
import HomeBannerDesktop from './../media/dashboard-1.png';
import DemoModal from './DemoModal';


const HomepageBanner = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigateTo = () => window.location.replace("https://demo.nscopelabs.com")

    return <>
        <DemoModal handleClose={handleClose} show={show} handleShow={handleClose} />
        <div className='banner_wrapper' style={{ paddingTop: '90px' }}>
            <Row>
                <Col lg={6} md={12} sm={12} className="banner_hero" >
                    <h1 style={{ marginBottom: '5px' }}>PCI Compliance Preparation</h1>
                    <h1>Made Painless</h1>
                    <p>NScope Advantage reduces the complexities of annual PCI Compliance activities.  Reducing the time and resources required through proper scoping, an accurate inventory, and Assessor level analysis.</p>
                    <div>
                        <Image alt="main banner" className='Zeffect homebanner_mobile' style={{ width: '100%', borderRadius: '15px', marginBottom: '10px' }} src={HomeBannerDesktop} />
                        <Button onClick={handleShow} className="banner_btns round_btn cyan_btn"><span>Request a Demo</span></Button>
                        <Button onClick={navigateTo} className='ml-15 round_btn banner_btns blue_btn'><span>Take a tour</span></Button>
                    </div>
                </Col>
                <Col lg={6} md={12} sm={12} className="banner_wrapper_col" >
                    <br />
                    <Image alt="main banner" className='Zeffect' style={{ width: '100%', borderRadius: '15px' }} src={HomeBannerDesktop} />
                </Col>
            </Row>
        </div>
    </>
}

export default HomepageBanner;