import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles/App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App(props) {
  return (
    <div>
      <ToastContainer />
      <Container fluid style={{ padding: 0 }} >
        {props.children}

        {/* Footer starts */}
        <Footer />
        {/* Footer ends */}

      </Container >
    </div >
  );
}

export default App;
