
import Header from '../components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles/App.scss';
import HeaderMobile from '../components/HeaderMobile';
import { Col, Row, Image } from 'react-bootstrap';
import aws1 from './../media/dashboard-3.jpeg';

function EducationTraining() {
  return (
    <>
      <div className='gradient-bg' style={{ marginBottom: 0 }}>
        <Header />
        <div className='main_content'>
          {/* navbar */}
          <HeaderMobile />
          {/* navbar ends */}

          {/* landing banner */}
          <div className='banner_wrapper' style={{ marginBottom: '25px', paddingTop: '30px' }}>
            <Row>
              <Col className="banner_hero" style={{ textAlign: 'center' }} >
                <h1 style={{ marginBottom: '5px' }}>Education &amp; Training</h1>
                <br />
                {/* <Row> */}
                {/* <Col sm={9}> */}
                <p style={{ margin: '20px' }}>Understanding a Shared Responsibility Matrix – IaaS Provider Impacts.</p>
                {/* </Col> */}
                {/* <Col sm={2}> */}
                <Image alt="aws" style={{ width: '100%', maxWidth: '750px', boxShadow: "0px 0px 10px limegreen", borderRadius: '5px' }} src={aws1} />
                {/* <small style={{ fontSize: '9px', display: 'block' }}>(Image created and owned by AWS <a href='https://aws.amazon.com/compliance/shared-responsibility-model/'>https://aws.amazon.com/compliance/shared-responsibility-model</a>)</small> */}
                <p style={{ margin: '10px' }}>Third Party Vendor Management - PCI Requirements, AOC review, ASV Scans, and Penetration Test Results.</p>

              </Col>
            </Row>
          </div>
          {/* landing banner ends */}
        </div>

        {/* Features box starts */}
        {/* <div className='features_box_wrapper' style={{ bottom: '-150px' }}>
          <Image alt="main banner" src={HomeBannerDesktop} />
        </div> */}
        {/* Features box starts */}
      </div >
      {/* gradient ends */}
    </>
  );
}

export default EducationTraining;
