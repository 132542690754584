import { Link } from "react-router-dom";

const BannerStrip = () => {
    return <>
        <div className='banner_strip'>
            <Link to="pci-services">How to Implement an Effective PCI version 4.0 Compliance Preparation Strategy</Link>
            <span> &nbsp; &gt; </span>
        </div>
    </>
};

export default BannerStrip;