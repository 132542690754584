import { Route, Routes } from 'react-router-dom'

import App from './containers/App'
import AdvantagePortal from './containers/AdvantagePortal'
import PCIServices from './containers/PCIServices'
import EducationTraining from './containers/EducationTraining'
import CompanyInfo from './containers/CompanyInfo'
import LandingPage from './containers/LandingPage'

const RoutesComp = () => {
    return <>
        <Routes>
            <Route path='/' element={<App><LandingPage /></App>} />
            <Route path='advantage-portal' element={<App><AdvantagePortal /></App>} />
            <Route path='pci-services' element={<App><PCIServices /></App>} />
            <Route path='education-training' element={<App><EducationTraining /></App>} />
            <Route path='company-info' element={<App><CompanyInfo /></App>} />
            <Route path='*' element={<App><LandingPage /></App>} />
        </Routes>
    </>
}

export default RoutesComp;