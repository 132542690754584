import { Button, Col, Row, Image } from 'react-bootstrap';
import React, { useState } from 'react';
import { BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import NScopeLogo from './../media/svg/nScope_logo_light.png';
import DemoModal from './DemoModal';
import Copyright from './CopyrightStrip';

const Footer = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return <>
        <DemoModal handleClose={handleClose} show={show} />
        <Row className='footer_wrapper'>
            <Col md={1}></Col>
            <Col md={7} className="footer_address">
                <Image alt="footer logo" height="35px" src={NScopeLogo} />
                <p>NScope Advantage, LLC.</p>
                <p>1631 ROCK SPRINGS RD</p>
                <p>PMB 222</p>
                <p>APOPKA, FL 32712</p>
            </Col>
            <Col md={3} style={{ textAlign: 'center' }}>
                <div className='social_links'>
                    <BsLinkedin fontSize={24} />
                    <BsTwitter fontSize={24} />
                    <BsYoutube fontSize={24} />
                </div>
                <Button onClick={handleShow} className="ml-15 round_btn cyan_btn"><span>Request a Demo</span></Button>
            </Col>
            <Copyright />
        </Row>
    </>
};

export default Footer;