import { Link } from "react-router-dom";

const Copyright = () => {
    return <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', marginTop: '20px' }}>
        <div style={{ display: 'flex', gap: '30px' }} className="navbar_links">
            <div>Terms</div>
            <div>Privacy</div>
            <div>Sitemap</div>
        </div>
        <p >Copyright ©2022 NScope Advantage, LLC. All Rights Reserved.</p>
    </div>
}

export default Copyright;