import React from 'react';
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import { toast } from 'react-toastify';

const DemoModal = (props) => {
    const { handleClose, show } = props;
    const formRef = useRef()

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_3ab99kc', 'template_eltp0r4', formRef.current, 'eOtTJS2z-GX7u-4vm')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        formRef.current.reset();
        handleClose();
        toast.success('Request Submitted! Lean back while we get in touch with you', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <h2><b>Request a demo</b></h2>
                    <p>Please fill in the form and one of our consultants will be in touch to help you.</p>
                    <Form id="request_demo" ref={formRef} onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Label>First Name<span>*</span></Form.Label>
                                <Form.Control required type="text" name="first_name" />
                            </Col>
                            <Col>
                                <Form.Label>Last Name<span>*</span></Form.Label>
                                <Form.Control required type="text" name="last_name" />
                            </Col>
                        </Row>
                        <br />
                        <Form.Label>Work Email<span>*</span></Form.Label>
                        <Form.Control required type="email" name="email" />
                        <br />
                        <Form.Label>Company<span>*</span></Form.Label>
                        <Form.Control required type="text" name="company" />
                        <br />
                        <Form.Label>Phone Number<span>*</span></Form.Label>
                        <Form.Control required type="tel" name="phone" />
                        <br />
                        <p>By submitting this form, I consent to the processing of the personal data provided above and to receive communications from Panther as described in the <a href="www.google.com">Privacy Policy</a>.</p>
                        <div><Button style={{ width: '100%', borderRadius: '50px' }} variant="secondary" type="submit" form="request_demo"  >Submit</Button></div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default DemoModal