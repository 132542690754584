import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Image, Form } from 'react-bootstrap';
import { ReactComponent as ListIcon } from '../media/list.svg';
import { ReactComponent as ChevronDown } from '../media/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../media/chevron-up.svg';
import NScopeLogo from './../media/svg/nScope_logo_light.png';
import { Link } from 'react-router-dom';
import DemoModal from './DemoModal';

const menuItemsData = [
    {
        itemText: 'Advantage Portal',
        itemLink: '/advantage-portal',
        isExpanded: false,
        itemOption: []//     { subItemText: 'Overview', subItemLink: '#' },
    },
    {
        itemText: 'PCI Services',
        itemLink: '/pci-services',
        isExpanded: false,
        itemOption: []
    },
    {
        itemText: 'Education & Training',
        itemLink: '/education-training',
        isExpanded: false,
        itemOption: []
    },
    {
        itemText: 'Company Info',
        itemLink: '/company-info',
        isExpanded: false,
        itemOption: []
    }
];

const SubItem = ({ submenu }) => {
    return <ul className='submenu'>
        {submenu && submenu.map((value) => <li key={value.subItemText}>
            <a href={value.subItemLink}>{value.subItemText}</a>
        </li>)}
    </ul>
}

const ExpandCollapesMenu = () => {
    const [menuItems, setMenuItems] = useState(menuItemsData);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onMenuItemClick = (item, isExpand) => {
        const menuItemsArray = [...menuItems];
        menuItemsArray.map(a => a.isExpanded = false);
        const indexVal = menuItemsArray.findIndex(a => a.itemText === item);
        menuItemsArray[indexVal].isExpanded = !isExpand;
        setMenuItems([...menuItemsArray]);
    }

    return <div className='menu_mobile'>
        <ul>
            {
                menuItems && menuItems.map((val) => <li key={val.itemText}>
                    <div className='menu_mobile_item' onClick={() => onMenuItemClick(val.itemText, val.isExpanded)}>
                        <Link to={val.itemLink}>{val.itemText}</Link>
                        {val.itemOption.length > 0 && <> {val.isExpanded ? <ChevronUp /> : <ChevronDown />} </>}
                    </div>
                    {
                        val.itemOption.length > 0 && val.isExpanded && <SubItem submenu={val.itemOption} />
                    }

                </li>)
            }
        </ul>
        <DemoModal handleClose={handleClose} show={show} handleShow={handleClose} />

        <Button onClick={handleShow} className="ml-15 round_btn cyan_btn hamburger_btn"><span>Request a Demo</span></Button>
        <Form.Control type="text" placeholder="Search" id='menuSearchBox' />
    </div>
}

const HeaderMobile = () => {
    const [showMenuItem, setShowMenuItem] = React.useState(false);
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        let scrollpos = window.scrollY;
        window.addEventListener('scroll', function () {
            scrollpos = window.scrollY;
            if (scrollpos > 10) {
                console.log('scrrooll add classs')
                setScroll(true)
            }
            else {
                console.log('scrrooll remove classs')
                setScroll(false)
            }
        });
    }, [])

    return <>
        <Row className={`navbar_mobile ${scroll ? 'add-bg-color' : ''}`} style={{ margin: '0 5%' }}  >
            <Col md={6} sm={6} xs={6}><Link to='/'><Image alt="banner-logo" src={NScopeLogo} className={'navbar_mobile_img'} /></Link></Col>
            <Col md={6} sm={6} xs={6}>
                <ListIcon s onClick={() => setShowMenuItem(!showMenuItem)} className="ml-15 round_btn cyan_btn hamburger_btn" />
            </Col>
        </Row>
        {showMenuItem && <ExpandCollapesMenu />}

    </>
};

export default HeaderMobile;