import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../styles/App.scss';
import BannerStrip from '../components/BannerStrip';
import Header from '../components/Header';
import HeaderMobile from '../components/HeaderMobile'
import HomepageBanner from '../components/HomepageBanner';
import CaseStudiesRibbon from '../components/CaseStudiesRibbon';
import HomeFeaturesBox from '../components/HomeFeaturesBox';

function LandingPage() {

  return (
    <>
      <div className='gradient-bg'>
        {/* Banner strip */}
        <BannerStrip />
        {/* Banner strip ends */}
        <Header />
        <div className='main_content'>
          {/* navbar */}
          <HeaderMobile />
          {/* navbar ends */}

          {/* landing banner */}
          <HomepageBanner />
          {/* landing banner ends */}
        </div>

        {/* Features box starts */}
        <HomeFeaturesBox />
        {/* Features box starts */}
      </div >
      {/* gradient ends */}

      {/* case studies ribbon */}
      <CaseStudiesRibbon />
      {/* case studies ribbon */}
    </>
  );
}

export default LandingPage;
