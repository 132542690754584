import FeatureBoxCloud from './../media/feature_box_cloud.png';
import FeatureBoxCode from './../media/feature_box_chart.png';
import FeatureBoxCycle from './../media/feature_box_cycle.png';

const HomeFeaturesBox = () => {
    return <>
        <div className='features_box_wrapper'>
            <div className="box">
                <img src={FeatureBoxCloud} alt="cloud" />
                <p className="title">On-Prem &amp; Cloud</p>
                <p className="sub_title">In-scope PCI relevant assets along with business partner services are categorized and assessed.</p>
            </div>
            <div className="box">
                <img src={FeatureBoxCode} alt="cloud" />
                <p className="title">Assessor Level Analysis</p>
                <p className="sub_title">Determining the applicable requirements for CAT 1 or CAT 2 components that impact your Cardholder Data Environment.</p>
            </div>
            <div className="box">
                <img src={FeatureBoxCycle} alt="cloud" />
                <p className="title">Compliance Workflows</p>
                <p className="sub_title">Collecting appropriate evidence to submit as artifacts to satisfy PCI requirements.</p>
            </div>
        </div>
    </>
}

export default HomeFeaturesBox